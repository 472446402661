<template>
  <div>
    <ct-table
      :loaded="loaded"
      :items="activeItems()"
      :fields="fields"
      :sticky-header="false"
      sort-by="registration_date"
      :sort-desc="true"
    >
      <template slot="cell(company_name)" />
      <template v-if="canRenew" slot="cell(active_renewal)" slot-scope="data">
        <b-form-checkbox
          v-if="canUpdateActiveRenewal(data.item)"
          v-model="data.item.active_renewal"
          name="check-button"
          class="toggle"
          variant="primary"
          switch
          size="md"
          :disabled="data.item.status !== 'completed'"
          @change="updateRenewalService(data.item)"
        >
          Auto Renewal
        </b-form-checkbox>
      </template>
      <template v-else slot="cell(active_renewal)"><span /></template>
      <template slot="cell(should_notify)" slot-scope="data">
        <b-form-checkbox
          v-if="canSetRemindMe(data.item)"
          v-model="data.item.should_notify"
          name="check-button"
          class="toggle"
          button-variant="primary"
          switch
          size="md"
          :disabled="data.item.active_renewal || data.item.status === 'removed'"
          @change="updateRemindMe(data.item)"
        >
          Renewal Reminder
        </b-form-checkbox>
      </template>
      <template slot="cell(actions)" slot-scope="data">
        <b-button
          v-if="canAddService(data.item)"
          v-b-modal.add-vehicle-service-modal
          variant="link"
          class="action-button"
          @click="addService(data.item)"
        >
          Additional Services
        </b-button>
        <b-button
          v-if="statusIsNew(data.item)"
          v-b-modal.edit-vehicle-registration-modal
          variant="link"
          class="action-button"
          @click="editVehicle(data.item)"
        >
          Edit
        </b-button>
        <b-button
          v-if="statusIsNew(data.item)"
          variant="link"
          class="action-button action-remove"
          @click="removeVehicle(data.item)"
        >
          Remove
        </b-button>
        <b-button
          v-if="noLongerHaveVehicle(data.item)"
          variant="link"
          class="action-button action-remove"
          @click="noLongerHaveVehicleRemove(data.item)"
        >
          I no longer have this vehicle
        </b-button>
      </template>
    </ct-table>

    <div v-if="removedItems().length" class="mt-5 mb-5">
      <b-card class="removed-vehicles">
        <b-button
          v-b-toggle.collapse-removed
          variant="link"
          aria-label="Removed Vehicles link"
          @click="showRemoved = !showRemoved"
        >
          <span>Removed Vehicles</span>
          <b-icon-chevron-up v-if="showRemoved" />
          <b-icon-chevron-down v-else />
        </b-button>
        <b-collapse id="collapse-removed">
          <ct-table
            :loaded="loaded"
            :items="removedItems()"
            :fields="removedFields"
            :sticky-header="false"
            :responsive="false"
          />
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CtTable from '@/components/shared/CtTable'

export default {
  name: 'VehicleCtTableRenderer',
  components: {
    CtTable,
  },
  props: {
    loaded: Boolean,
    items: Array,
    fields: Array,
    canRenew: Boolean,
  },
  data() {
    return {
      selectedVehicleToEdit: {},
      showRemoved: false,
    }
  },
  computed: {
    removedFields: function () {
      return this.fields.slice(0, -4)
    },
  },
  methods: {
    ...mapActions('vehicleRegistration', [
      'update',
      'addRenewal',
      'endRenewal',
      'removeVehicleRegistration',
      'getVehicleRegistrationFee',
    ]),
    makeToast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      })
    },
    errorModal(msg) {
      this.makeToast('danger', 'Failure', msg)
    },
    activeItems() {
      return this.items.filter(item => item.status !== 'removed')
    },
    removedItems() {
      return this.items.filter(item => item.status === 'removed')
    },
    searchChanged(searchObject) {
      this.query = searchObject.query
      this.appliedFilters = searchObject.filters
    },
    editVehicle(vehicle) {
      this.$emit('editVehicle', vehicle)
    },
    vehicleRegistrationCompleted() {
      this.$emit('vehicleRegistrationCompleted')
    },
    updateCompleted() {
      this.$emit('updateCompleted')
    },
    updateVehicleFailed(){
      this.$emit('updateVehicleFailed')
    },
    statusIsNew(vehicle){
      return vehicle.status === 'new'
    },
    removeVehicle(vehicle) {
      this.$emit('removeVehicle', vehicle)
    },
    noLongerHaveVehicle(vehicle){
      const activeStatuses = ['new', 'removed']
      return !activeStatuses.includes(vehicle.status)
    },
    noLongerHaveVehicleRemove(vehicle) {
      this.$emit('removeCompletedVehicle', vehicle)
    },
    canSetRemindMe(vehicle){
      return vehicle.registration_period !== 'permanent'
    },
    async updateRemindMe(vehicle) {
      this.$emit('updateRemindMe', vehicle)
    },
    canUpdateActiveRenewal(vehicle) {
      return vehicle.model_year && vehicle.registration_period === 'annual'
    },
    updateRenewalService(vehicle){
      if(this.serviceAddCancelled){
        this.serviceAddCancelled = !this.serviceAddCancelled
      } else {
        vehicle.active_renewal ? this.addRenewalService(vehicle) : this.endRenewalService(vehicle)
      }
    },
    canAddService(vehicle){
      return (vehicle.status === 'new' || vehicle.status ==='completed') && vehicle.vehicle_type !== 'boat'
    },
    addService(vehicle) {
      this.$emit('addService', vehicle)
    },
    async addRenewalService(vehicle) {
      const fees = await this.getVehicleRegistrationFee(vehicle.id)
      let feeTotal = 125
      fees.forEach(fee =>
        feeTotal += fee.price
      )
      const feeText = 'Your estimated cost at the time of renewal will be $' + parseFloat(feeTotal).toFixed(2) + '. You will be sent an invoice 60 days prior to renewal.'
      const res = await this.$bvModal.msgBoxConfirm(feeText, {
          title: 'Add Vehicle Renewal Service?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        })
      if (res) {
        this.loaded = false
        try {
          await this.addRenewal({ id: vehicle.id })
          this.$emit('load')
          this.makeToast('primary', 'Success', 'Renewal Service has been added!')
        } catch (error) {
          this.errorModal(`We cannot Add your vehicle renewal service at this time. Please try again later.`)
          this.serviceAddCancelled = true
          vehicle.active_renewal = false
        }
      } else {
        this.serviceAddCancelled = true
        vehicle.active_renewal = false
      }
      this.loaded = true
    },
    async endRenewalService(vehicle){
      const res = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to end your Renewal Service?', {
          title: 'Cancel Vehicle Renewal Service?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        })
      if(res){
        this.loaded = false
        try {
          await this.endRenewal({ id: vehicle.id })
          vehicle.active_renewal = false
          this.makeToast('primary', 'Success', 'Renewal Service Removed')
        } catch(error) {
          vehicle.active_renewal = true
          this.errorModal('We can not delete your renewal service at this time.')
        }
      } else {
        this.serviceAddCancelled = true
        vehicle.active_renewal = true
      }
      this.loaded = true
    },
  },
}
</script>

<style lang="scss" scoped>
  $primary: #283d96;
  $primary-dark: darken($primary, 10%);
  $disabled: #e9ecef;
  $font: #212529;
  $label-width: 25%;
  $label-background: #f7f7fb;

  @media (max-width: 735px) {
    :deep(.table.b-table.b-table-stacked) {
      tbody {
        tr.b-table-empty-row td {
          display: block;
        }

        > tr {
          [data-label] > div {
            width: calc(100% - $label-width);
          }

          > td {
            display: flex;
            padding: 0;

            &.actions button {
              padding: 1rem 0.5rem;
              margin: 0 0.5rem 1rem 0.5rem;
            }

            &[data-label]::before {
              width: $label-width;
              text-align: left;
              background: $label-background;
              padding: 0 4px;
              min-height: 1.625rem;
            }

            &[data-label="Actions"] {
              & > div {
                width: 100%;
              }

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 736px) {
    :deep(.table.b-table) {
      overflow: hidden;

      td {
        padding-left: calc(0.25rem + 0.35em);
        vertical-align: middle;
        .custom-control-label {
          font-size: 0.875rem;

          &::before, &::after {
            margin-top: 0.875rem;
          }
        }
      }

      .actions {
        max-width: 15rem;
      }

      .toggle {
        width: 6rem;
      }
    }
  }

  .action-button {
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    margin: 0 .125rem;
    color: $primary;

    &.action-remove {
      color: darken($danger, 15%);
    }

    &:hover {
      color: $primary-dark;
      text-decoration: none;

      &.action-remove {
        color: darken($danger, 25%);
      }
    }
  }

  :deep(.table.b-table) {
    font-size: 0.9375rem;

    th {
      font-weight: 600;
      border-bottom: none;
      background: $label-background;
    }

    > thead > tr > th[aria-sort] {
      background-size: 0.45em .75em;
      background-position: left 0.35rem center !important;
    }

    tr {
      font-weight: 400;

      td {
        cursor: auto;
      }

      .custom-control-label::before,
      .custom-control-label::after {
        cursor: pointer;
      }
      .custom-control-input:checked ~ .custom-control-label::before,
      .custom-control-input:active ~ .custom-control-label::before,
      .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
        background-color: $primary !important;
        border-color: $primary !important;
      }

      .custom-control-input:disabled~.custom-control-label::after {
        cursor: default;
      }

      .custom-control-input:disabled~.custom-control-label::before {
        background-color: $disabled !important;
        border-color: $disabled !important;
        cursor: default;
      }
    }
  }

  .removed-vehicles {
    .card-body {
      padding: 0;
    }

    button {
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-decoration: none;
      color: $font;
      font-weight: 500;
      padding: 1rem 0.5rem;

    }
  }
</style>
