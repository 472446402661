var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.active
    ? _c(
        "b-modal",
        {
          attrs: {
            id: "edit-vehicle-registration-modal",
            title: "Edit Vehicle",
            centered: "",
            size: "md",
            "hide-footer": "",
          },
        },
        [
          _vm.loading ? _c("b-spinner") : _vm._e(),
          _c("template", { slot: "modal-title" }, [
            _c("h3", { staticStyle: { margin: "0", padding: "0" } }, [
              _vm._v("\n      Edit Your Vehicle\n    "),
            ]),
          ]),
          !_vm.loading
            ? _c("div", { staticClass: "mb-4" }, [
                _c("div", { staticClass: "d-block" }, [
                  _vm.active
                    ? _c(
                        "form",
                        {
                          ref: "form",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.updateVehicle.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("validation-observer", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ invalid }) {
                                    return [
                                      _vm._l(
                                        _vm.selectedFields,
                                        function (field) {
                                          return _c(
                                            "b-row",
                                            { key: field.id },
                                            [
                                              _c(
                                                "b-col",
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: field.label,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            rules:
                                                              field.required
                                                                ? `required|${field.key.toString()}`
                                                                : null,
                                                            immediate: "",
                                                            name: field.label,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  errors,
                                                                }) {
                                                                  return [
                                                                    field.type ===
                                                                    "text"
                                                                      ? _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                                state:
                                                                                  !errors[0],
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .mutableVehicle[
                                                                                    field
                                                                                      .key
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.mutableVehicle,
                                                                                      field.key,
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "mutableVehicle[field.key]",
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    field.type ===
                                                                    "boolean"
                                                                      ? _c(
                                                                          "b-form-checkbox",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "button-variant":
                                                                                  "success",
                                                                                switch:
                                                                                  "",
                                                                                size: "md",
                                                                                state:
                                                                                  !errors[0],
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .mutableVehicle[
                                                                                    field
                                                                                      .key
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.mutableVehicle,
                                                                                      field.key,
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "mutableVehicle[field.key]",
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    field.type ===
                                                                    "select"
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            style:
                                                                              errors[0]
                                                                                ? _vm.errorStyle
                                                                                : "",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "multiselect",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    options:
                                                                                      field.values,
                                                                                    "allow-empty": false,
                                                                                    multiple: false,
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .mutableVehicle[
                                                                                        field
                                                                                          .key
                                                                                      ],
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.mutableVehicle,
                                                                                          field.key,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "mutableVehicle[field.key]",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    field.type ===
                                                                    "number"
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            style:
                                                                              errors[0]
                                                                                ? _vm.errorStyle
                                                                                : "",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-form-input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    min: field.min,
                                                                                    max: field.max,
                                                                                    type: "number",
                                                                                    state:
                                                                                      !errors[0],
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .mutableVehicle[
                                                                                        field
                                                                                          .key
                                                                                      ],
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.mutableVehicle,
                                                                                          field.key,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "mutableVehicle[field.key]",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    field.type ===
                                                                    "select-luxury_tax"
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "multiselect",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    options:
                                                                                      field.values,
                                                                                    label:
                                                                                      "text",
                                                                                    "track-by":
                                                                                      "value",
                                                                                    "allow-empty": false,
                                                                                    multiple: false,
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .mutableVehicle[
                                                                                        field
                                                                                          .key
                                                                                      ],
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.mutableVehicle,
                                                                                          field.key,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "mutableVehicle[field.key]",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    field.type ===
                                                                    "select-company"
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            style:
                                                                              errors[0]
                                                                                ? _vm.errorStyle
                                                                                : "",
                                                                          },
                                                                          [
                                                                            field.type ===
                                                                            "select-company"
                                                                              ? _c(
                                                                                  "multiselect",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        "track-by":
                                                                                          "company_id",
                                                                                        label:
                                                                                          "name",
                                                                                        return:
                                                                                          "company_id",
                                                                                        placeholder:
                                                                                          "Select A Company",
                                                                                        options:
                                                                                          _vm.allCompanies,
                                                                                        "allow-empty": false,
                                                                                        multiple: false,
                                                                                        state:
                                                                                          !errors[0],
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .mutableVehicle[
                                                                                            "company_id"
                                                                                          ],
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.mutableVehicle,
                                                                                              "company_id",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "mutableVehicle['company_id']",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    field.type ===
                                                                    "select-make"
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            style:
                                                                              errors[0]
                                                                                ? _vm.errorStyle
                                                                                : "",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "multiselect",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    "placeholder-text":
                                                                                      "Choose A Vehicle Make",
                                                                                    options:
                                                                                      _vm.allMakes,
                                                                                    "allow-empty": false,
                                                                                    multiple: false,
                                                                                  },
                                                                                on: {
                                                                                  changed:
                                                                                    _vm.retrieveModels,
                                                                                  input:
                                                                                    _vm.retrieveModels,
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .mutableVehicle[
                                                                                        field
                                                                                          .key
                                                                                      ],
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.mutableVehicle,
                                                                                          field.key,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "mutableVehicle[field.key]",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    field.type ===
                                                                    "select-model"
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            style:
                                                                              errors[0]
                                                                                ? _vm.errorStyle
                                                                                : "",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "multiselect",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    "placeholder-text":
                                                                                      "Choose A Vehicle Model",
                                                                                    options:
                                                                                      _vm.allModels,
                                                                                    multiple: false,
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .mutableVehicle[
                                                                                        field
                                                                                          .key
                                                                                      ],
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.mutableVehicle,
                                                                                          field.key,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "mutableVehicle[field.key]",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    field.type ===
                                                                      "date" &&
                                                                    !_vm
                                                                      .mutableVehicle
                                                                      .active_renewal
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            style:
                                                                              errors[0]
                                                                                ? _vm.errorStyle
                                                                                : "",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-date-picker",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    id: "datepicker-trigger",
                                                                                    value: `${_vm.mutableVehicle["registration_date"]}`,
                                                                                    color:
                                                                                      "blue",
                                                                                    popover:
                                                                                      {
                                                                                        visibility:
                                                                                          "click",
                                                                                      },
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .mutableVehicle[
                                                                                        "registration_date"
                                                                                      ],
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.mutableVehicle,
                                                                                          "registration_date",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "mutableVehicle['registration_date']",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          errors[0]
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "justify-content-sm-center",
                                        },
                                        [
                                          _c(
                                            "b-button-group",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    type: "submit",
                                                    variant: "primary",
                                                    disabled: invalid,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Update Vehicle\n              "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              486686004
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }